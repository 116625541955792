import React from "react"
import styled from "styled-components"
import { FaRegBookmark, FaBookmark } from "react-icons/fa"
import firebase from "../../firebase"
import { accessValid } from "../../auth/utils"
import { useModal } from "../../layout/modal/modal-provider"
import RegisterModal from "./modal"
import useGlobalState from "../../../utils/useGlobalState"
import { graphql, useStaticQuery } from "gatsby"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const ScrollWrap = ({ children, block, location, active }) => {
  var {
    wordpressAcfOptions: {
      options: { product_id, enable_bookmarking },
    },
  } = useStaticQuery(CONFIG)

  var [state] = useGlobalState()

  var { openFn, setComponent } = useModal()

  if (block.__typename === "WordPressAcf_body") {
    return <div>{children}</div>
  }
  // Prep Data to save
  const data = {
    id: block.id,
    path: location.pathname,
  }
  /* eslint-disable default-case */
  switch (block.__typename) {
    case "WordPressAcf_heading":
      data.type = "heading"
      data.value = block.value || "No value set"
      data.isPrimary = block.primary
      break
    case "WordPressAcf_image":
      data.type = "image"
      data.value = block.image_caption || "No caption set"
      break
    case "WordPressAcf_video":
      data.type = "video"
      data.value = block.video_caption || "No caption set"
      break
    /* eslint-enable default-case */
  }

  const save = () => {
    if (!state.user) {
      setComponent(<RegisterModal />)
      openFn()
      return
    }
    var pay = { ...data, path: location.pathname }
    var uid = state?.user?.id
    var doc = firebase.firestore().doc(`user-favs/${uid}`)
    var { arrayRemove, arrayUnion } = firebase.firestore.FieldValue

    doc.set(
      { [product_id]: active ? arrayRemove(pay) : arrayUnion(pay) },
      { merge: true }
    )
  }

  var a = active && accessValid(state) && state?.user

  var bookmark_button = <>
                          <Icon className="bookmark" onClick={save} active={a}>
                            {a ? <FaBookmark /> : <FaRegBookmark />}
                          </Icon>
                        </>

  return (
    <Container active={a}>
      <Scroll id={block.id} />
      {children}
      {!!enable_bookmarking ? bookmark_button : null}
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Scroll = styled.div`
  position: absolute;
  top: -110px;
`
const Container = styled.div`
  width: 100%;
  position: relative;

  &:hover {
    .bookmark {
      opacity: 1;
    }
  }
  .bookmark {
    opacity: ${({ active }) => (active ? "1" : "0.5")};
    left: -30px;
    right: auto;
    top: 5px;
    transition: 0.2s all ease-in-out;
  }
`
const Icon = styled.div`
  position: absolute;
  top: ${({ active }) => (active ? "5px" : "-22px")};
  color: ${({ active }) => (active ? "#FF0000" : "#777")};
  right: 5px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
`
const CONFIG = graphql`
  {
    wordpressAcfOptions {
      options {
        product_id
        enable_bookmarking
      }
    }
  }
`

export default ScrollWrap
