import React from "react"
import Layout from "../components/layout"
import styled, { keyframes } from "styled-components"
import { graphql, Link } from "gatsby"
import { MdArrowBack } from "react-icons/md"
import Block from "../components/shared/block"
import { path as getPath } from "../utils"
import SlideOutContent from "../components/pages/article/slide-out-content"
import { useTheme } from "../components/layout/theme-manager"
import print from "../components/pages/article/printArticle"
import { IoIosArrowForward } from "react-icons/io"
import { FiPrinter } from "react-icons/fi"
import useGlobalState from "../utils/useGlobalState"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const ArticlePage = props => {
  var {
    path,
    location,
    pageContext: { next, previous },
    data: {
      wordpressWpArticles: {
        title,
        acf: { flexible_content_articles: blocks, meta, topic },
        yoast_meta,
      },
      wordpressAcfOptions: {
        options: { header_logo },
      },
      allWordpressAcfContributor: { nodes: contributorNodes },
    },
  } = props

  const [auth] = useGlobalState()
  // const [auth, setAuth] = useAuthState({ hasAccess: false })

  // used for slide-out nav
  const headings = blocks.filter(isHeading)

  // prettier-ignore
  const { theme: { getColor }} = useTheme()

  const media = blocks.filter(v => isImage(v) || isVideo(v))

  // meta info
  const article_details = getPath(["article_details"], meta)
  const patient_handouts = getPath(["patient_handouts"], meta)

  const contributors = contributorNodes.reduce(
    (a, c) => ({ ...a, [c.wordpress_id]: { ...c.acf } }),
    {}
  )

  const blockRef = React.useRef()

  const printArticle = () =>
    print(
      blockRef.current.innerHTML,
      title,
      article_details,
      meta.references,
      getColor,
      header_logo.source_url
    )
  const addIDs = text => {
    const textArray = text.split("\n")
    const finalText = textArray.map((line, i) =>
      line.replace(`<li>`, `<li id="reference${i}">`)
    )
    return finalText.join("\n")
  }

  return (
    <Layout
      // =======================
      seo={{ yoast: yoast_meta }}
      // =======================
      slideOutHeader={
        <Back to="/">
          <MdArrowBack size="30px" className="icon" /> Index
        </Back>
      }
      // =======================
      slideOutContentComponent={({ setOpen }) => (
        <SlideOutContent
          {...{
            setOpen,
            printArticle,
            contributors,
            patient_handouts,
            path,
            headings,
            article_details,
            media,
          }}
        />
      )}
      // =======================
      mainHeader={({ open, setOpen }) => (
        <Text>
          {/* {!open && ( */}
          <ExpandButton onClick={() => setOpen(true)}>
            <IoIosArrowForward />
          </ExpandButton>
          {/* )} */}
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Text>
      )}
      // =======================
      slideOutInitial={true}
      // =======================
      mainContent={({ open, setOpen }) => (
        <>
          <Mobile>
            <SlideOutContent
              {...{
                setOpen,
                printArticle,
                contributors,
                patient_handouts,
                path,
                headings,
                article_details,
                media,
                itemsExpand: false,
              }}
            />
          </Mobile>
          <Block
            {...{
              blockRef,
              blocks,
              location,
              previous,
              next,
              title,
              topic: topic?.name,
            }}
            sideBarOpen={open}
          />
          {auth && auth.hasAccess && (
            <Print onClick={printArticle} className="hidden">
              <FiPrinter size="25px" />
              Print This Article
            </Print>
          )}
        </>
      )}
      // =======================
      mainBottom={
        meta &&
        meta.references !== null && (
          <References id="reference">
            <h2>References</h2>
            <div
              dangerouslySetInnerHTML={{ __html: addIDs(meta.references) }}
            />
          </References>
        )
      }
      // =======================
    />
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Print = styled.button`
  border: none;
  font-family: "Poppins", sans-serif;
  padding: 15px 20px;
  background: #ccc;
  color: #333;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: 0.2s all ease-in-out;
  border-radius: 10px;
  margin: 30px 0 0 50px;
  &:hover {
    background: #bbb;
  }
  svg {
    margin-right: 10px;
  }
`

const ExpandButton = styled.div`
  position: absolute;
  top: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  transform: translate(50%, -50%);
  padding: 5px;
  background: white;
  border-radius: 200px;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 1px 1px #0000000a;
  display: none;
  @media only screen and (max-width: 786px) {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    display: none;
    svg {
      margin-right: 0 !important;
      color: ${({ theme: { getColor } }) => getColor(900)};
    }
  }
`

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 786px) {
    display: block;
    /* margin: 0 -40px; */
  }
`
const References = styled.div`
  padding: 0 30px 0 50px;
  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Poppins", sans-serif;
  }
  p,
  li {
    color: rgba(0, 0, 0, 0.85);
    margin: 15px 0;
  }
  ol,
  ul {
    margin: 10px 0;
    padding-inline-start: 20px;
  }
  a {
    color: rgba(0, 0, 0, 0.85);
    overflow-wrap: break-word;
    word-break: break-word;
    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
`

const Text = styled.div`
  margin-left: 20px;
  position: relative;
  svg {
    margin-right: 20px;
  }
`

const bounce = keyframes`
 0%, 20%, 50%, 80%, 100% {transform: translateX(0);}
 40% {transform: translateX(-7px);}
 60% {transform: translateX(-2px);}
}
`

const Back = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ theme: { getColor } }) => getColor(200)};
  color: ${({ theme: { getColor } }) => getColor(200, true)};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 300;
  height: 75px;
  svg {
    margin-right: 5px;
  }

  &:hover {
    svg {
      animation: ${bounce} 1s infinite;
    }
  }
`

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================
const isHeading = node => node.__typename === "WordPressAcf_heading"
const isImage = node => node.__typename === "WordPressAcf_image"
const isVideo = node => node.__typename === "WordPressAcf_video"

export const ARTICLE = graphql`
  query($slug: String!) {
    wordpressWpArticles(slug: { eq: $slug }) {
      slug
      title
      wordpress_id
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        topic {
          name
          slug
        }
        flexible_content_articles {
          ... on WordPressAcf_body {
            id
            value
          }
          ... on WordPressAcf_heading {
            id
            value
            primary
          }
          ... on WordPressAcf_image {
            id
            image {
              slug
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image_caption
          }
          ... on WordPressAcf_video {
            id
            video
            video_caption
          }
        }
        meta {
          references
          article_details {
            title
            content {
              acf_fc_layout
              value
              contributor {
                wordpress_id
                post_title
                post_name
              }
            }
          }
          patient_handouts {
            title
            media_url
            isExternal
            attachment {
              slug
              source_url
            }
          }
        }
      }
    }
    allWordpressAcfContributor {
      nodes {
        wordpress_id
        acf {
          bio
          qualifications
          photo {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        header_logo {
          source_url
          slug
        }
      }
    }
  }
`

export default ArticlePage
