import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { getUserFromToken } from "../../utils/lib"
import { genHash } from "../../utils/index"

import { FiCheckCircle } from "react-icons/fi"

import {
  succeed,
  fail,
  loading,
  notLoading,
  accessValid,
  openLogin,
  update,
} from "./utils"
import axios from "axios"
import useGlobalState from "../../utils/useGlobalState"

const INITIAL = { loading: true, error: null, value: null }
const id = x => x
const noOp = id
//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
// Handles
// * Restricting access
// * Referred by bookshelf with access token
// * New login
// ============================

// const ArticleAuth = ({ children }) => children
const ArticleAuth = ({ children }) => {
  // ======================
  // 🌍🌍 GLOBALSTATE 🌍🌍
  // ======================
  var [state, setState] = useGlobalState()
  // const [state, setState] = useAuthState({ hasAccess: false })
  // ======================
  // 🏠🏠 LOCAL STATE 🏠🏠
  // ======================
  const data = useStaticQuery(PASSWORD)

  var {
    wordpressAcfOptions: {
      options: {
        free,
        product_id,
        front_end_url,

        locked_out_message: { form_heading, form_description },
      },
    },
  } = data

  const [user, setUser] = useState(INITIAL)
  const [message, setMessage] = useState(INITIAL)

  // > this could be grouped with the above
  //    check if value emptyString and use that as title
  const [login, setLogin] = useState({
    title: "Login",
    value: false,
    loading: false,
  })
  const [mustLogin, setMustLogin] = useState(false)

  // ===================
  // 🧨🧨 EFFECTS 🧨🧨
  // ===================
  var uid = state?.user?.id
  var hash = state?.hash
  // useEffect(() => (!accessValid(state) ? setLogin(openLogin) : noOp), [state])

  useEffect(() => {
    if (free || accessValid(state)) return noOp

    if (state?.token) {
      var token = state?.token
      setLogin(loading)

      getUserFromToken(token)
        .then(async user => {
          setUser(succeed(user))

          var d = await axios.get(
            `${process.env.GATSBY_LACTED_URL}/wp-json/iable/v1/access/?uid=${user.id}&pid=${product_id}`
          )

          var {
            data: {
              data: { response: hasAccess },
            },
          } = d
          // var hasAccess = data?.data?.response

          if (!hasAccess)
            setMessage(
              update({
                value: `<a class="button" href="${process.env.GATSBY_LACTED_URL}/cart/?add-to-cart=${product_id}&redirect-url=${front_end_url}">Buy This Publication</a>`,
              })
            )

          var payload = {
            user,
            token,
            hasAccess,
            hash: genHash(),
          }
          if (!hasAccess)
            setMessage(
              fail(
                `<a class="button" href="${process.env.GATSBY_LACTED_URL}/cart/?add-to-cart=${product_id}&redirect-url=${front_end_url}">Buy This Publication</a>`
              )
            )
          setState(update(payload))
          setLogin(notLoading)
        })
        .catch(e => {
          console.error({ e })
          //  🧤 🚨 CATCH 🚨 🧤
          setUser(fail())

          setLogin(notLoading)
          var code = e?.response?.data?.code

          if (code === "rest_not_logged_in")
            setLogin(openLogin("Please Login (Custom message)"))
          else setMessage(fail(e.message))
        })
      //  🧤 🚨 END CATCH 🚨 🧤
    } else {
      setMustLogin(true)
    }
  }, [product_id, uid, hash]) // eslint-disable-line react-hooks/exhaustive-deps

  // ==================
  // 📺📺 RENDER 📺📺
  // ==================

  if (free || (!!accessValid(state) && state?.user))
    return (
      <div id="uni" key="uni">
        <section>{React.Children.toArray(children)}</section>
      </div>
    )

  var origin = typeof window === "undefined" ? "/" : window.location.pathname

  return (
    <Container key="oth" id="oth">
      <Style />
      {React.Children.toArray(children).slice(0, 2)}
      <Wrapper>
        {(login.value && !login.loading) || (!user.value && !user.loading) ? (
          <LoginRequest
            {...{
              product_id,
              front_end_url,
              free,
              origin,
              form_heading,
              form_description,
            }}
          />
        ) : (
          <Box>
            {mustLogin ? (
              <LoginRequest
                {...{
                  product_id,
                  front_end_url,
                  free,
                  origin,
                  form_heading,
                  form_description,
                }}
              />
            ) : (
              <>
                <h1>Verification</h1>
                <Item label="User" {...user} value={user?.value?.name} />
                {[user].every(({ loading }) => !loading) && (
                  <Item label="Info" {...message} value={message?.value} />
                )}
              </>
            )}
          </Box>
        )}
      </Wrapper>
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Item = ({ label, msg = "fetching...", loading, error, value }) => {
  return (
    <Load>
      <span>{label}:</span>
      {loading ? (
        <>
          <Spinner /> {msg}
        </>
      ) : error ? (
        <Error className="err" dangerouslySetInnerHTML={{ __html: error }} />
      ) : (
        <>
          <FiCheckCircle size="25px" />{" "}
          <span dangerouslySetInnerHTML={{ __html: value }} />
        </>
      )}
    </Load>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background: linear-gradient(0deg, white 75%, #ffffff00 100%);
  margin-top: -150px;
  padding-top: 120px;
  z-index: 3;
  h1 {
    text-align: center;
    font-size: 22px;
  }
`

const Box = styled.div`
  border-radius: 6px;
  box-shadow: 0 1px 3px 0px #929292;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const Error = styled.span`
  margin: 0 10px;
  &.err {
    font-weight: 300;
    font-size: 16px;
  }
`

const Button = styled(Link)`
  background: #cccccc;
  color: #4a4a4a;
  border: 0;
  border-radius: 4px;
  padding: 10px 15px;
  /* width: 100%; */
  cursor: pointer;
  display: block;
  transition: all 200ms ease-in-out;
  margin: 0 auto;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    background: #222;
    color: #cccccc;
  }
`

const LoginRequest = ({
  product_id,
  front_end_url,
  free,
  form_heading,
  form_description,
  origin,
}) => (
  <div style={{ textAlign: "center" }}>
    <h1>{form_heading}</h1>
    <p>{form_description}</p>
    <Links>
      <a
        href={`${process.env.GATSBY_LACTED_URL}/cart/?add-to-cart=${product_id}&redirect-url=${front_end_url}`}
      >
        {free ? "Sign Up" : "Purchase"}
      </a>
      <Button to="/login/" state={{ origin }}>
        Please Login
      </Button>
    </Links>
  </div>
)

const Links = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    > * {
      width: fill-available;
    }
  }
  > * {
    background: #cccccc;
    color: #4a4a4a;
    border: 0;
    border-radius: 4px;
    padding: 10px 15px;
    /* width: 100%; */
    cursor: pointer;
    display: block;
    transition: all 200ms ease-in-out;
    margin: 0 auto;
    margin-top: 20px;
    text-decoration: none;
    &:hover {
      background: #222;
      color: #cccccc;
    }
  }
`

const Style = () => (
  <style
    dangerouslySetInnerHTML={{
      __html: `.slide-out .hidden{
  display:none;
}
#reference{
  display:none;
}

.bookmark svg {
  display: none;
}
`,
    }}
  />
)

/**
 * height: 100vh;
 * width: 100vw;
 * display: flex;
 * align-items: center;
 * justify-content: center;
 * flex-direction: column;
 * font-family: Roboto;
 */
const Container = styled.div`
  /* height: 100vh; */
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto;
  position: relative;
`

const animate = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  color: green;
  &:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    animation: ${animate} 1.2s linear infinite;
  }
`

export const Spin = Spinner

const Load = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  span {
    font-weight: 500;
    font-size: 20px;
  }
  svg {
    margin: 0 10px;
    color: #008773;
  }
  ${Spinner} {
    margin: 0 10px;
  }
  .button {
    background: #cccccc;
    color: #4a4a4a;
    border: 0;
    border-radius: 4px;
    padding: 10px 15px;
    /* width: 100%; */
    cursor: pointer;
    display: block;
    transition: all 200ms ease-in-out;
    margin: 0 auto;
    text-decoration: none;
    &:hover {
      background: #222;
      color: #cccccc;
    }
  }
`
const PASSWORD = graphql`
  {
    wordpressAcfOptions {
      options {
        product_id
        free
        front_end_url
        locked_out_message {
          form_description
          form_heading
        }
      }
    }

    file(name: { eq: "secure" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ArticleAuth
