import React from "react"
import styled from "styled-components"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Body = ({ block: { value }, modify = x => x }) => {
  return <Container dangerouslySetInnerHTML={{ __html: modify(value) }} />
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  a {
    color: rgba(0, 0, 0, 0.8);
    transition: all 200ms ease-in-out;
    &:hover {
      color: ${({ theme: { getColor } }) => getColor(900)};
    }
    &[href*="#reference"] {
      color: ${({ theme: { getColor } }) => getColor(800)};
      top: -10px;
      padding: 3px;
      text-decoration: none;
      border-radius: 50px;
      width: 12px;
      height: 12px;
      text-align: center;
      display: inline-block;
      transition: all 200ms ease-in-out;
      &:hover {
        color: ${({ theme: { getColor } }) => getColor(500, 1)};
        background-color: ${({ theme: { getColor } }) => getColor(500)};
        font-weight: 600;
        transform: translateY(-2px);
      }
    }
  }
`

export default Body
