import React, { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import ScrollWrap from "./scroll-wrap"
import Body from "./body"
import Heading from "./heading"
import Image from "./image"
import { path, wrapHighlight } from "../../../utils/index"
import Video from "./video"
import { FaChevronUp, FaChevronRight, FaChevronLeft } from "react-icons/fa"
import { Link } from "gatsby"
import ArticleAuth from "../../auth/article-auth"
import useGlobalState from "../../../utils/useGlobalState"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Block = ({
  blocks,
  location,
  next,
  previous,
  sideBarOpen,
  blockRef,
  title,
  topic = "Index",
}) => {
  // used to highlight searchTerm from search navigation
  const searchTerm = path(["state", "searchTerm"], location)
  const hash = path(["hash"], location)

  const [mustHighlight, setMustHighlight] = React.useState(true)
  useEffect(() => {
    setMustHighlight(true)
    var id = setTimeout(() => setMustHighlight(false), 3000)
    return () => clearTimeout(id)
  }, [location])

  const configLink = node => ({
    to: "/" + getSlug(node),
    title: getTitle(node),
  })

  var [state] = useGlobalState()
  var l = state?.favs?.length
  var favs = React.useMemo(
    () => state?.favs?.reduce((a, { id }) => ({ ...a, [id]: true }), {}) || {},
    [l] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <Container ref={blockRef}>
      <Crumbs>
        <Link to="/">{topic}</Link> <FaChevronRight />{" "}
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Crumbs>
      <ArticleAuth>
        {blocks.map((block, key) => (
          <BlockItem
            active={favs[block.id]}
            key={block.id}
            {...{ block, hash, searchTerm, location, mustHighlight }}
          />
        ))}
      </ArticleAuth>
      <ScrollTop className="no-print" onClick={() => window.scroll(0, 0)}>
        <FaChevronUp size="25px" />
      </ScrollTop>
      <LeftNav key="leftie" className="no-print" sideBarOpen={sideBarOpen}>
        <CircleArrowLink {...configLink(previous)} direction="left" />
      </LeftNav>
      <Nav key="rightie" className="no-print">
        <CircleArrowLink {...configLink(next)} direction="right" />
      </Nav>
    </Container>
  )
}

const Crumbs = styled.div`
  background: #eee;
  margin: 0 -50px;
  padding: 10px 10px 10px 50px;
  a,
  span {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
  }
  span {
    font-weight: 600;
  }
  svg {
    color: rgba(0, 0, 0, 0.15);
    margin: 0 10px;
  }
  a:hover {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const CircleArrowLink = ({ to, title, direction = "left" }) => (
  <Detail {...{ to, title }}>
    <Box className={direction}>
      {direction === "left" ? (
        <FaChevronLeft size="25px" />
      ) : (
        <FaChevronRight size="25px" />
      )}
    </Box>
  </Detail>
)

const Box = styled.div`
  width: 25px;
  height: 25px;
`
const bounceRight = keyframes`
       0%, 20%, 50%, 80%, 100% {transform: translateX(0);}
       40% {transform: translateX(7px);}
       60% {transform: translateX(2px);}
      }
      `
const bounceLeft = keyframes`
       0%, 20%, 50%, 80%, 100% {transform: translateX(0);}
       40% {transform: translateX(-7px);}
       60% {transform: translateX(-2px);}
      }
      `

const Detail = styled(Link)`
  width: 60px;
  height: 60px;
  border-radius: 3000px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background: #00000096;
  backdrop-filter: blur(2px);
  color: white;
  &:hover {
    svg {
      animation: ${bounceRight} 1s infinite;
    }
  }
`

const MENU_WIDTH = 100
const SLIDE_WIDTH = 322

const LeftNav = styled.div`
  position: fixed;
  left: ${({ sideBarOpen }) =>
    sideBarOpen
      ? parseInt(MENU_WIDTH + SLIDE_WIDTH - 5) + "px"
      : MENU_WIDTH - 5 + "px"};
  top: 50%;
  z-index:21;
  width: 15px;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  transform: translateY(-50%);
  transition: 0.2s all ease-in-out;
  clip: rect(0px 200px 204px 2px);
  @media only screen and (max-width: 786px) {
    display: none;
    top: auto;
    bottom: 60px;
    left: 50%;
    transform: translateX(-100%);
  clip:unset;
    /* left: ${({ sideBarOpen }) =>
      sideBarOpen ? parseInt(SLIDE_WIDTH - 2) + "px" : -2 + "px"}; */
  }
  ${Detail} {
    cursor: pointer;
    transform: translateX(-75%);
    transition: 0.2s all ease-in-out;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    &:hover {
      svg {
        animation: ${bounceLeft} 1s infinite;
      }
    }
  }
  &:hover {
    ${Detail} {
      transform: translateX(10px);
        @media only screen and (max-width: 786px) {
          transform: inherit;
        }

    }
  }
`
const Nav = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  width: 15px;
  z-index:21;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  transform: translateY(-50%);
  @media only screen and (max-width: 786px) {
    display: none;
    top: auto;
    bottom: 60px;
    left: 50%;
    transform: translateX(100%);
    /* left: ${({ sideBarOpen }) =>
      sideBarOpen ? parseInt(SLIDE_WIDTH - 2) + "px" : -2 + "px"}; */
  }
  ${Detail} {
    cursor: pointer;
    transform: translateX(calc(80%));
    transition: 0.2s all ease-in-out;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  &:hover {
    ${Detail} {
      transform: translateX(-10px);
      @media only screen and (max-width: 786px) {
          transform: inherit;
        }
    }
  }
`

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-7px);}
  60% {transform: translateY(-2px);}
}
`

const ScrollTop = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 2000px;
  background: white;
  box-shadow: 0px 1px 1px 1px #a0a0a0;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  @media only screen and (max-width: 786px) {
    bottom: 60px;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(72, 84, 100, 0.22) 0px 7px 8px 0px;
    svg {
      animation: ${bounce} 1s infinite;
    }
  }
  svg {
    transition: 0.2s all ease-in-out;
  }
`

const BlockItem = ({
  active,
  block,
  hash,
  searchTerm,
  mustHighlight,
  location,
}) => {
  const Comp = types[block.__typename] ? types[block.__typename] : NoBlock
  const canHighlight = "#" + block.id === hash && mustHighlight

  return (
    <ScrollWrap
      key={"scroll-" + block.id}
      active={active}
      block={block}
      location={location}
    >
      <Comp
        block={block}
        searchTerm={searchTerm}
        modify={
          canHighlight
            ? content => wrapHighlight(content, searchTerm || content)
            : x => x
        }
      />
    </ScrollWrap>
  )
}

const NoBlock = () => <div>NO COMPONENT CONFIGURED</div>

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const pulse = color => keyframes`
{
  0% {
    box-shadow: 0 0 0 0 ${color};
  }
  70% {
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
`

const Container = styled.div`
  padding: 0px 50px;
  a {
    overflow-wrap: break-word;
    word-break: break-word;
  }
  @media only screen and (max-width: 786px) {
    width: calc(100% - 100px);
  }
  mark {
    transition: 0.2s all ease-in-out;
    background: ${({ theme: { getColor } }) => getColor(100)};
    color: ${({ theme: { getColor } }) => getColor(100, true)};
    border-radius: 4px;
    animation: ${({ theme: { getColor } }) =>
        pulse(hexToRgbA(getColor(100), 0.8))}
      2s infinite;
    margin: -6px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 6px;
    padding-top: 0;
    padding-bottom: 0;
  }
`

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================

const propOr = prop => fallback => node => (node ? node[prop] : fallback)
const getSlug = propOr("slug")("")
const getTitle = propOr("title")("All Topics")

function hexToRgbA(hex, opacity = 1) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    )
  }
  throw new Error("Bad Hex")
}

const types = {
  WordPressAcf_heading: Heading,
  WordPressAcf_body: Body,
  WordPressAcf_image: Image,
  WordPressAcf_video: Video,
}

export default Block
