import React from "react"
import styled from "styled-components"
import { FaSearch } from "react-icons/fa"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const NavLightboxItem = ({ value, onClick }) => (
  <NavWrap onClick={onClick}>
    {value}
    <FaSearch className="expand" />
  </NavWrap>
)

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const NavWrap = styled.div`
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 30px;
  color: ${({ theme: { getColor } }) => getColor(100, true)};
  &:hover {
    svg.expand {
      width: 25px;
      transform: scale(1);
      margin-left: 5px;
      opacity: 0.5;
    }
  }
  svg.expand {
    width: 0;
    transform: scale(0);
    transition: 0.2s all ease-in-out;
  }
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

export default NavLightboxItem
