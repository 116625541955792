import React, { useEffect } from "react"
import styled from "styled-components"
import { TiChevronRight, TiChevronLeft } from "react-icons/ti"
import Dots from "./dots"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ImageSlider = ({ images, activeIndex = 0, dontCloseAttribute }) => {
  const [active, setActive] = React.useState(activeIndex)

  const next = () => {
    const nextIdx = active === images.length - 1 ? 0 : active + 1
    setActive(nextIdx)
  }
  const prev = () => setActive(active === 0 ? images.length - 1 : active - 1)
  const x = React.useRef(0)

  useEffect(() => {
    const idxs = images.length - 1
    if (active > idxs) setActive(idxs)
  }, [images]) // eslint-disable-line react-hooks/exhaustive-deps

  const registerXPos = ({ changedTouches }) =>
    (x.current = changedTouches[0].clientX)

  const changeSlide = ({ changedTouches }) => {
    var newX = changedTouches[0].clientX
    if (x.current < newX) prev()
    else next()
  }

  return (
    <Container className="container-slide">
      {images.length > 1 && (
        <Nav left onClick={prev} {...dontCloseAttribute}>
          <TiChevronLeft size="50px" {...dontCloseAttribute} />
          <Dont {...dontCloseAttribute} />
        </Nav>
      )}
      <SlideWrap
        length={images.length}
        active={active}
        onTouchStart={registerXPos}
        onTouchEnd={changeSlide}
        className="wrapper-slide"
      >
        {images.map((media, i) => (
          <Panel key={i}>
            {isImage(media) ? (
              <img
                alt=""
                src={media.image.localFile.childImageSharp.fluid.src}
                {...dontCloseAttribute}
              />
            ) : (
              <div
                {...dontCloseAttribute}
                dangerouslySetInnerHTML={{
                  __html: media.video
                    .replace('width="500"', 'width="100%"')
                    .replace('height="291"', 'height="470"'),
                }}
              />
            )}
            <div className="caption" {...dontCloseAttribute}>
              {isImage(media)
                ? media.image_caption || "Image: " + i
                : media.video_caption || "Video: " + media.video_caption}
            </div>
          </Panel>
        ))}
      </SlideWrap>
      {images.length > 1 && (
        <Nav right onClick={next} {...dontCloseAttribute}>
          <TiChevronRight size="50px" />
          <Dont {...dontCloseAttribute} />
        </Nav>
      )}
      <Dots
        setActive={setActive}
        items={images}
        active={active}
        dontCloseAttribute={dontCloseAttribute}
      />
    </Container>
  )
}

// to allow for close functionality when clicking on overlay
// this sits on top of the svg whos children we cant access
// and add the noClick attribute to. It acts as a mask
const Dont = styled.div`
  width: 50px;
  height: 50px;
  transform: translateY(-50px);
`

const isImage = node => node.__typename === "WordPressAcf_image"
// const isVideo = node => node.__typename === "WordPressAcf_video"

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Panel = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  iframe {
    height: 470px;
    width: 80vw;
  }

  .caption {
    padding: 10px;
    color: white;
    width: 100%;
    text-align: center;
  }

  img {
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }
`

const SlideWrap = styled.div`
  display: grid;
  height: 470px;
  width: ${({ length }) => length * 100 + "%"};
  grid-template-columns: ${({ length }) => "repeat(" + length + ",1fr)"};
  transition: 0.2s all ease-in-out;
  transform: translateX(
    ${({ length, active }) => (-100 / length) * active + "%"}
  );

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 300px;
  }
`

const Container = styled.div`
  height: 550px;
  display: grid;
  align-items: center;
  transition: 0.2s all ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 300px;
  }

  overflow: hidden;
  position: relative;
`
const Nav = styled.div`
  cursor: pointer;
  position: absolute;
  width:50px;
  height:50px;
  top: 50%;
  z-index:21;
  transform: translateY(-50%);
  transition: 0.2s all ease-in-out;
  ${({ left, right }) => (left ? "left:5px;" : "right:5px;")}
  color: ${({ theme: { getColor } }) => getColor(300)};
  &:hover {
    transform: translateX(${({ left }) => (left ? "-5px" : "5px")})
      translateY(-50%);
  }
`

export default ImageSlider

// const PLACEHOLDER = graphql`
// 	{
// 		file(relativePath: { eq: "team-image-placeholder.jpg" }) {
// 			childImageSharp {
// 				fluid {
// 					...GatsbyImageSharpFluid
// 				}
// 			}
// 		}
// 	}
// `
