import React from "react"
import styled from "styled-components"
import { listHasElements, isImage } from "../../../utils"
import useWindowSize from "../../../utils/useWindowSize"
import { TiDocumentText } from "react-icons/ti"
import { GoInfo } from "react-icons/go"
import NavItem from "./nav-item"
import ImageSlider from "../../shared/image-slider.jsx"
import { FaRegImage, FaRegImages } from "react-icons/fa"
import { MdOndemandVideo } from "react-icons/md"
import SlideOutExpand from "../../shared/slide-out-expand"
import NavLightboxItem from "./nav-lightbox-item"
import { useModal } from "../../layout/modal/modal-provider"
import { AiOutlineCloudDownload } from "react-icons/ai"
import { FiFile } from "react-icons/fi"
import Author from "./author"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const SlideOutContent = ({
  headings,
  article_details,
  media,
  patient_handouts,
  path,
  contributors,
  setOpen,
  itemsExpand = true,
}) => {
  const { openFn, setComponent } = useModal()

  const items = React.useMemo(
    () => [
      {
        // ==============================
        title: "Topic Outline",
        // ==============================
        pred: listHasElements(headings),
        hasPadding: false,
        icon: TiDocumentText,
        content: () =>
          headings.map(({ value, id, primary }) => (
            <NavItem
              key={id}
              to={path + "#" + id}
              value={value}
              primary={primary}
            />
          )),
      },
      {
        // ==============================
        title: "Article Details",
        // ==============================
        pred:
          listHasElements(article_details) &&
          article_details.some(
            ({ content: [{ value, contributor }] }) => !!value || !!contributor
          ),
        hasPadding: true,
        icon: GoInfo,
        content: () =>
          article_details.map(
            ({ title, content: [{ value, contributor }] }, i) =>
              !value && !contributor ? null : (
                <DetailItem key={i}>
                  <h4>{title}</h4>
                  <Detail {...{ value, contributor, contributors }} />
                </DetailItem>
              )
          ),
      },
      {
        // ==============================
        title: "Media",
        // ==============================
        pred: listHasElements(media),
        hasPadding: false,
        icon: FaRegImages,
        content: () =>
          media.map((item, idx) => {
            const [key, fallback, icon] = isImage(item)
              ? mediaLabels[0]
              : mediaLabels[1]

            return (
              <NavItemWrapper>
                <NavLightboxItem
                  key={idx}
                  value={
                    <LabelIcon
                      value={item[key] || fallback + idx}
                      icon={icon}
                    />
                  }
                  onClick={() => {
                    setComponent(
                      <ImageSlider images={media} activeIndex={idx} />
                    )
                    openFn()
                  }}
                />
              </NavItemWrapper>
            )
          }),
      },
      {
        // ==============================
        title: "Handouts",
        // ==============================
        pred: listHasElements(patient_handouts),
        hasPadding: false,
        icon: AiOutlineCloudDownload,
        content: () =>
          patient_handouts.map(
            ({ title, isExternal, media_url, attachment }, key) =>
              isExternal ? (
                <HandoutWrapper target="_blank" href={media_url} key={key}>
                  <FiFile className="icon" />
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </HandoutWrapper>
              ) : (
                <HandoutWrapper
                  target="_blank"
                  href={attachment?.source_url}
                  key={key}
                >
                  <FiFile className="icon" />
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </HandoutWrapper>
              )
          ),
      },
    ],
    [
      contributors,
      headings,
      article_details,
      media,
      patient_handouts,
      path,
      openFn,
      setComponent,
    ]
  )

  const { width } = useWindowSize()

  return (
    <Container className="slide-out">
      {items.map(({ title, pred, icon: Icon, content, hasPadding }, key) =>
        !pred ? null : (
          <SlideOutExpand
            {...{ key, title }}
            key={key}
            className={key > 0 && "hidden"}
            icon={<Icon size="25px" />}
            hasPadding={hasPadding}
            content={content()}
            isMobile={width < 786}
            setOpen={setOpen}
            initial={itemsExpand}
          />
        )
      )}
      <div className="copyright">
        &copy; 2017-{new Date().getFullYear()} IABLE, Inc.
      </div>
    </Container>
  )
}

const NavItemWrapper = styled.div`
  padding: 10px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  transition: all 200ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`
const HandoutWrapper = styled.a`
  color: ${({ theme: { getColor } }) => getColor(400, true)};
  padding: 10px 15px;
  display: block;
  text-decoration: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  transition: all 200ms ease-in-out;
  text-transform: capitalize;
  .icon {
    width: 25px;
    margin-right: 5px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`

const mediaLabels = [
  ["image_caption", "Image: ", <FaRegImage />],
  ["video_caption", "Video: ", <MdOndemandVideo />],
]

const Detail = ({ value, contributor, contributors }) => {
  const { setComponent, openFn } = useModal()
  return value ? (
    <div
      className="content"
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  ) : (
    <div>
      {contributor
        ? contributor.map((post, i) => (
            <NavLightboxItem
              key={i}
              value={post.post_title}
              onClick={() => {
                setComponent(
                  <Author
                    contributor={{
                      ...post,
                      ...contributors[post.wordpress_id],
                    }}
                  />
                )
                openFn()
              }}
            />
          ))
        : "--"}
    </div>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  background: ${({ theme: { getColor } }) => getColor(200)};
  color: ${({ theme: { getColor } }) => getColor(200, true)};
  width: 100%;
  height: 100%;
  .copyright {
    background: ${({ theme: { getColor } }) => getColor(100)};
    padding: 20px;
    text-align: center;
  }
`

const LabelIconWrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 8px;
  width: 100%;
  line-height: 1.2;
  &:hover {
    > svg {
      margin-left: 0px;
    }
  }
  > svg {
    width: 25px;
    opacity: 0.6;
    transition: 0.2s all ease-in-out;
    transform: scale(1);
  }
`

const DetailItem = styled.div`
  h4,
  p {
    margin: 0;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
  }
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

const LabelIcon = ({ value, icon }) => (
  <LabelIconWrap>
    {icon}
    {value}
  </LabelIconWrap>
)

export default SlideOutContent
