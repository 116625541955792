import React from "react"
import styled from "styled-components"

const Dots = ({ items, active, setActive, dontCloseAttribute }) => {
  return (
    <DotWrap {...dontCloseAttribute}>
      {items.map((item, i) => (
        <Dot
          {...dontCloseAttribute}
          key={i}
          onClick={() => setActive(i)}
          active={active === i}
        />
      ))}
    </DotWrap>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const DotWrap = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  left: 0;
  right: 0;
  justify-content: center;
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ active, theme: { getColor } }) =>
    active ? getColor(300) : "#d4d4d4"};
  border-radius: 200px;
  cursor: pointer;
  margin: 5px;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
`

export default Dots
