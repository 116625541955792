import React from "react"
import styled from "styled-components"
import posed, { PoseGroup } from "react-pose"
import { graphql, useStaticQuery, Link } from "gatsby"

function RegisterModal({ dontCloseAttribute }) {
  var {
    wordpressAcfOptions: {
      options: {
        locked_out_message: {
          bookmarks_pop_up_heading,
          bookmarks_pop_up_message,
        },
        product_id,
        front_end_url,
      },
    },
  } = useStaticQuery(QUERY)

  return (
    <Container {...dontCloseAttribute}>
      <PoseGroup {...dontCloseAttribute}>
        <Title key="title" {...dontCloseAttribute}>
          <div
            dangerouslySetInnerHTML={{ __html: bookmarks_pop_up_heading }}
            {...dontCloseAttribute}
          />
        </Title>
        <PopUpContentRow key="p1" className="first" {...dontCloseAttribute}>
          <div
            dangerouslySetInnerHTML={{ __html: bookmarks_pop_up_message }}
            {...dontCloseAttribute}
          />
        </PopUpContentRow>
        <ButtonRow key="p2" {...dontCloseAttribute}>
          <div {...dontCloseAttribute}>
            <Link to="/login">Login</Link>
            <a
              onClick={() =>
                (window.location.href = `${process.env.GATSBY_LACTED_URL}/cart/?add-to-cart=${product_id}&redirect-url=${front_end_url}`)
              }
              href={`${process.env.GATSBY_LACTED_URL}/cart/?add-to-cart=${product_id}&redirect-url=${front_end_url}`}
            >
              Register
            </a>
          </div>
        </ButtonRow>
      </PoseGroup>
    </Container>
  )
}

const Title = posed.h2({
  exit: { y: "-20%", opacity: 0, delay: 1300 },
  enter: { y: 0, opacity: 1, delay: 1300 },
})
const PopUpContent = posed.p({
  exit: { x: "-20%", opacity: 0, delay: 1600 },
  enter: { x: 0, opacity: 1, delay: 1600 },
})
const P2 = posed.div({
  exit: { x: "-20%", opacity: 0, delay: 1900 },
  enter: { x: 0, opacity: 1, delay: 1900 },
})

const PopUpContentRow = styled(PopUpContent)`
  padding: 30px;
  margin: 0;
  p {
    margin: 0;
  }
  @media (max-width: 600px) {
    padding: 20px;
  }
`
const ButtonRow = styled(P2)`
  background-color: ${({ theme: { getColor } }) => getColor(500)};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  button,
  a {
    border-radius: 4px;
    text-decoration: none;
    border: 0;
    padding: 4px 15px;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin: 0 10px;
    background: ${({ theme: { getColor } }) => getColor(300, true)};
    color: ${({ theme: { getColor } }) => getColor(300)};
    transition: all 200ms ease-in-out;
    &:hover {
      background: ${({ theme: { getColor } }) => getColor(100)};
      color: ${({ theme: { getColor } }) => getColor(100, true)};
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  background: ${({ theme: { getColor } }) => getColor(400)};
  padding: 0;
  border-radius: 20px;
  box-shadow: 2px 8px 12px 0px #00000059;
  overflow: hidden;
  color: ${({ theme: { getColor } }) => getColor(400, true)};
  h2 {
    background-color: ${({ theme: { getColor } }) => getColor(500)};
    padding: 30px;
    margin: 0;
    font-size: 1.8rem;
    line-height: 1.3;
    width: calc(100% - 60px);
  }
  @media (max-width: 600px) {
    margin: 20px;
    h2 {
      padding: 20px;
      width: calc(100% - 40px);
    }
  }
`

var QUERY = graphql`
  {
    wordpressAcfOptions {
      options {
        product_id
        front_end_url
        locked_out_message {
          bookmarks_pop_up_heading
          bookmarks_pop_up_message
        }
      }
    }
  }
`

export default RegisterModal
