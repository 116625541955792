import React from "react"
import styled from "styled-components"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Heading = ({ block: { value, primary = true }, modify = x => x }) => {
  return (
    <Container>
      <Text
        as={primary ? "h2" : "h3"}
        dangerouslySetInnerHTML={{ __html: modify(value) }}
      />
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Text = styled.h3``
const Container = styled.div`
  h2,
  h3 {
    font-family: "Poppins", sans-serif;
  }
  h2 {
    font-size: 28px;
    font-weight: 500;
    border-bottom: 2px solid #eee;
    color: ${({ theme: { getColor } }) => getColor(900)};
    margin: 40px 0 10px 0;
    line-height: 1.2;
  }
  h3 {
    font-weight: 500;
    font-size: 23px;
    margin: 30px 0 10px 0;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
  }
`

export default Heading
