import { listHasElements } from "../../../utils"
import footer from "../../../images/foot.jpg"

const printArticle = (
  html,
  title,
  article_details,
  references,
  getColor,
  header
  // footer
) => {
  var mywindow = window.open("", "PRINT", "height=400,width=600")

  mywindow.document.write("<html><head><title>" + document.title + "</title>")
  mywindow.document.write(`
    <link href="https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap" rel="stylesheet">
    <style>
    body {
      font-family: Roboto;
      background:#ccc;
      border-radius:8px;
    }
    .bookmark{
      display:none;
    }
    .no-print{
      display:none;
    }
    .video iframe{
      display:none;
    }
    .video{
      background: #efefef;
      border-radius: 4px;
      display:flex;
      align-items:center;
      justify-content:center;
      color: white;
      color: #545454;
      padding: 20px;
      font-weight: 700;
      font-size: 22px;
    }
    h1{
      text-align:center;
      color: ${getColor(700)};
    }
    h3{
      color: ${getColor(500)};
      font-size: 25px;
    }
    .caption {text-align: center;}
    .meta{
        text-align: center;
    }
    .meta h2,p{
        margin:0;
    }
    .meta h2{
        margin:10px 0;
        font-size:18px;
    }
    .meta p{

        font-size:14px;
    }
    .gatsby-image-wrapper{
        margin-top: 20px;
    }
    .caption{
        margin-top: 10px;
    font-weight: 700;
    }
    .references{
        padding: 20px;
        background: #efefef;
        margin: 20px 0;
    }
    img.full{
     width: 100%;
    }
    header {
      text-align:center;
    }
    header img {
      max-width:400px;
      margin:20px auto;
    }
    .article {
      background: #fff;
      max-width:800px;
      margin:0 auto;
      padding:20px;
    }
    header + div {
      border:2px solid #ccc;
      padding:10px 0;
      text-align:center;
      margin-top:20px;
    }
    .refs li {
      margin:15px 0;
    }
    h2 {
      border-bottom:2px solid #ccc;
      font-size:30px;
      color:#333;
    }
    h3 {
      color:#333;
      font-size:22px;
    }
    </style>`)

  mywindow.document.write("</head><body >")
  mywindow.document.write(`<div class="article">`)
  mywindow.document.write(`<header>`)
  mywindow.document.write(`<img class="full" src="${header}"/>`)
  mywindow.document.write(`<h1>${title}</h1>`)
  mywindow.document.write(renderArticleDetails(article_details))
  mywindow.document.write(`</header>`)
  mywindow.document.write(html)
  mywindow.document.write(`<footer>`)
  mywindow.document.write(`<h2>References</h2>`)
  mywindow.document.write(renderReferences(references))
  mywindow.document.write(`</h2>`)
  mywindow.document.write(`<img class="footer full" src="${footer}"/>`)
  mywindow.document.write(`</div>`)
  mywindow.document.write("</body></html>")

  mywindow.document.close() // necessary for IE >= 10
  mywindow.focus() // necessary for IE >= 10*/

  mywindow.print()

  return true
}

const renderArticleDetails = article_details =>
  listHasElements(article_details)
    ? `<div class="meta">${article_details.map(
        ({ title, content: [{ value, contributors }] }) => {
          const body = contributors
            ? contributors.map(({ post_title }) => `<p>${post_title}</p>`)
            : [value]
          return [`<h2>${title}</h2>`, ...body].join``
        }
      ).join``}</div>`
    : ""

const renderReferences = references =>
  references ? `<div class="refs">${references}</div>` : ""

export default printArticle
