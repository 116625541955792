import React from "react"
import styled from "styled-components"
import { FiPlay } from "react-icons/fi"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Video = ({ block: { video, video_caption } }) => {
  return (
    <Container className="video">
      <div
        className="iframe"
        dangerouslySetInnerHTML={{
          __html: video
            .replace('width="500"', 'width="1600"')
            .replace('height="291"', 'height="900"'),
        }}
      />
      <FiPlay className="play" size="50px" />
      <Caption>{video_caption}</Caption>
      {/* <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U'  /> */}
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  overflow: hidden;
  border-radius: 4px;
  margin: 40px 0;
  padding: 0;
  .play {
    display: none;
  }
  > div.iframe {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
const Caption = styled.div`
  padding: 10px;
  /* background: #d2d2d2; */
  color: #000000;
  text-align: center;
  width: 100%;
  margin-top: -2px;
  border-radius: 0 0 4px 4px;
`

export default Video
