import React from "react"
import styled, { keyframes } from "styled-components"
import posed from "react-pose"
import { useState } from "react"
import { FaChevronDown } from "react-icons/fa"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const SlideOutExpand = ({
  title,
  icon,
  content,
  hasPadding,
  className,
  isMobile,
  setOpen: s,
  initial = true,
}) => {
  const [open, setOpen] = useState(initial)
  return (
    <Container className={className}>
      <Title onClick={() => setOpen(p => !p)} open={open}>
        <Left>
          {icon} {" " + title}
        </Left>{" "}
        <FaChevronDown className="chev" />
      </Title>
      <Expand pose={open ? "open" : "closed"}>
        <Content
          closeOnClick={
            isMobile && title.toLowerCase().includes("topic outline")
          }
          onClick={
            isMobile && title.toLowerCase().includes("topic outline")
              ? () => s(false)
              : () => {}
          }
          hasPadding={hasPadding}
        >
          {content}
        </Content>
      </Expand>
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const bounce = keyframes`
 0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
 40% {transform: translateY(-7px);}
 60% {transform: translateY(-2px);}
}
`

const bounceUp = keyframes`
 0%, 20%, 50%, 80%, 100% {transform: translateY(0) rotate(180deg);}
 40% {transform: translateY(-7px) rotate(180deg);}
 60% {transform: translateY(-2px) rotate(180deg);}
}
`

const Container = styled.div`
  margin: 0 0 2px 0;
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 10px;
  }
`
const Title = styled.div`
  padding: 20px;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  background: ${({ theme: { getColor } }) => getColor(300)};
  color: ${({ theme: { getColor } }) => getColor(300, true)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  svg.chev {
    transition: 0.2s all ease-in-out;
    transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
  }
  &:hover {
    svg.chev {
      animation: ${({ open }) => (open ? bounceUp : bounce)} 1s infinite;
      transform: rotate(${({ open }) => (open ? "0deg" : "180deg")});
    }
  }
`

const Pose = posed.div({ open: { height: "auto" }, closed: { height: 0 } })
const Expand = styled(Pose)`
  overflow: hidden;
  background: ${({ theme: { getColor } }) => getColor(200)};
  color: ${({ theme: { getColor } }) => getColor(200, true)};
`
const Content = styled.div`
  padding: ${({ hasPadding }) => (hasPadding ? "20px" : "0")};
`

export default SlideOutExpand
