import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { path } from "../../../utils"
import parse from "html-react-parser"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Author = ({ contributor, dontCloseAttribute }) => {
  const { photo, post_title, bio, qualifications } = contributor
  const fluid = path(["localFile", "childImageSharp", "fluid"], photo)
  const getWidth = ref => path(["current", "imageRef", "current", "width"], ref)
  const getHeight = ref =>
    path(["current", "imageRef", "current", "height"], ref)

  const coverRef = React.useRef()
  const [state, setState] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => {
      setState(true)
    }, 1500)
  }, [])
  return (
    <Container {...dontCloseAttribute}>
      <Close>
        Close <span>X</span>
      </Close>
      {state && (
        <Cover
          width={getWidth(coverRef)}
          height={getHeight(coverRef)}
          {...dontCloseAttribute}
        />
      )}
      {fluid && <Pic ref={coverRef} fluid={fluid} {...dontCloseAttribute} />}
      <h4 {...dontCloseAttribute}>
        {post_title} - {qualifications}
      </h4>

      <Content {...dontCloseAttribute}>
        {parse(bio, {
          replace: x => {
            x.attribs = { ...x.attribs, ...dontCloseAttribute }
            return x
          },
        })}
      </Content>
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Close = styled.div`
  position: absolute;
  top: 15px;
  right: 7px;
  z-index: 999999;
  background: white;
  padding: 2px 10px;
  border-radius: 4px;
  cursor: pointer;
`
const Content = styled.div`
  padding: 20px;
`
const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  z-index: 99999;
`

const Container = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  overflow-y: scroll;
  max-height: 90vh;
  h4 {
    text-align: center;
  }
`
const Pic = styled(Img)``

export default Author
