import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Image = ({ block: { image, image_caption } }) => {
  return (
    <Container>
      {/* <img src={image.slug} alt="" /> */}
      {image?.localFile?.childImageSharp?.fluid ? (
        <Img
          style={{ height: "400px" }}
          imgStyle={{ height: "400px", objectFit: "contain" }}
          objectFit="contain"
          objectPosition="50% 50%"
          fluid={image?.localFile?.childImageSharp?.fluid}
        />
      ) : null}
      <Caption className="caption">{image_caption}</Caption>
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  margin: 40px 0;
  overflow: hidden;
  border-radius: 4px;
  .gatsby-image-wrapper {
    img {
      margin: 0 auto;
    }
  }
`
const Caption = styled.div`
  padding: 10px;
  /* background: #d2d2d2; */
  color: #000000;
  text-align: center;
  width: 100%;
`

export default Image
