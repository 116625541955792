import React from "react"
import styled from "styled-components"
import { FaArrowRight } from "react-icons/fa"
import { Link } from "gatsby"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const NavItem = ({
  to,
  value,
  href,
  as = Link,
  target,
  icon = <FaArrowRight />,
  primary = true,
}) => (
  <NavWrap
    {...(as === "a" ? { href, target, as } : { to, as })}
    state={{ searchTerm: value }}
    className={!primary && "secondary"}
    isPrimary={primary}
  >
    {!primary && <span className="secondary-label">—&nbsp;</span>}{" "}
    <span dangerouslySetInnerHTML={{ __html: value }} />
  </NavWrap>
)

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const NavWrap = styled.div`
  cursor: pointer;
  font-weight: ${({ isPrimary }) => (isPrimary ? "600" : "400")};
  transition: 0.2s all ease-in-out;
  display: flex;
  align-items: center;
  color: ${({ theme: { getColor } }) => getColor(100, true)};
  text-decoration: none;
  border-top: ${({ isPrimary }) =>
    isPrimary ? "1px solid rgba(0, 0, 0, 0.15)" : "none"};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  padding: ${({ isPrimary }) => (isPrimary ? "12px 20px" : "8px 20px")};
  margin: 0 !important;
  svg {
    width: 0;
    transition: 0.2s all ease-in-out;
  }
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  .secondary-label {
    opacity: 0.3;
  }
`

export default NavItem
